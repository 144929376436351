import {autoinject, bindable, TaskQueue} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {PatientItem} from "../resources/classes/Patient/PatientItem";
import {I18N} from "aurelia-i18n";
import {PatientChangeNotifier} from 'resources/services/PatientChangeNotifier';
import {QuestionnaireService} from 'resources/services/QuestionnaireService';
import {LocationService} from 'resources/services/LocationService';
import {PatientService} from 'resources/services/PatientService';
import {FhirService} from "../resources/services/FhirService";
import {NitTools} from "../resources/classes/NursitTools";
import {UserService} from "../resources/services/UserService";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {App} from "../app";
import {EncounterService} from "../resources/services/EncounterService";
import {ConfigService} from "../resources/services/ConfigService";
import {TakeOfflineList} from "../resources/elements/take-offline-list";
import {DialogService} from "aurelia-dialog";
import SystemHeaders from "../resources/classes/SystemHeaders";
import {IFormSetting} from "../resources/classes/IFormSettings";
import {IdLogikService} from "../resources/services/IdLogikService";
import {NursingVisitsProtocol} from "./patient/nursing-visits-protocol";
import { PermissionService } from 'resources/services/PermissionService';

const environment = require("../../config/environment.json");

@autoinject
export class HomeList {
    static instance: HomeList;
    enableRoomColors: boolean = false;
    fhirService: FhirService; // FhirService;
    offLineEnabled: boolean = false;
    @bindable encounterId: string;
    selectedEncounter: any;
    router: Router;
    subscriberId: string;
    locationService: LocationService;
    patientChangeNotifier: PatientChangeNotifier;
    taskQueue: TaskQueue;
    static CurrentWardId: string;
    @bindable wardId: string;
    searchName: string = "";
    searchInput: HTMLInputElement;
    searchButton: HTMLButtonElement;
    isLoading: boolean = false;
    loadedPatient = undefined;
    isLoadingPatients: boolean = false;
    subScriberId: string;
    effectiveSearch: string;
    i18n: I18N;
    userService: UserService;
    app: App;
    patientService: PatientService;
    loaded: boolean = false;
    selectedPatient;
    isOffline: boolean = false;
    transferText: string;
    wardName: string;
    listParam: string;
    formSettingAssessment: IFormSetting;
    roomColor: string;
    colorButton: HTMLButtonElement;
    patientListRoot: HTMLDivElement;
    roomsCache: any[];

    constructor(router: Router, notifier: PatientChangeNotifier, queue: TaskQueue, i18n: I18N, locationService: LocationService,
                app: App, patientService: PatientService, protected encounterService: EncounterService, protected dialogService: DialogService, idLogikService: IdLogikService, permissionService: PermissionService) {
        this.router = router;
        this.taskQueue = queue;
        this.locationService = locationService;
        this.patientChangeNotifier = notifier;
        this.fhirService = new FhirService();
        this.userService = new UserService(router, i18n, undefined, undefined, idLogikService, permissionService);
        this.app = app;
        this.i18n = i18n;
        this.patientService = patientService;
        this.offLineEnabled = FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled === true;

        if (ConfigService.Debug) {
            window["patientList"] = this;
        }

        HomeList.instance = this;
    }

    get selectedEncounterId(): string {
        return PatientItem.SelectedPatient ? PatientItem.SelectedPatient.encounterId : '';
    }

    get patients(): PatientItem[] { // holds the loaded items
        return PatientItem.Patients;
    }

    get filteredTagPatients(): any[] {
        let result = this.effectiveSearch ? this.patients.filter(o => o.search.indexOf(this.effectiveSearch) > -1) : this.patients;
        if (this.roomColor && this.roomColor !== 'transparent') {
            result = result.filter(o => String(o.roomColor).toUpperCase() == this.roomColor.toUpperCase());
        }

        return result;
    }

    get style(): string {
        return RuntimeInfo.Style;
    }

    get wards(): any[] {
        return this.userService.wards ? this.userService.wards.filter(o => o.status === "active" && o.name && o.id.indexOf('_contained_') === -1)
            .sort((a, b) => {
                return a.name.localeCompare(b.name)
            }) : [];
    }

    encounterIdChanged() {
        PatientItem.HighlightRelatedEncounters();
    }

    activate(params) {
        if (params.id) {
            this.encounterId = params.id;
        }
    }

    silentReload() {

    }

    async attached() {
        if (UserService.IsLoggedIn) {
            await QuestionnaireService.Fetch();
            await this.locationService.fetch();
        }

        this.taskQueue.queueTask(() => {
            this.selectInitialWard();
            this.loaded = true;
            this.load();
        })

        this.enableRoomColors = RuntimeInfo.Features["enableRoomColors"];

        this.subScriberId = this.patientChangeNotifier?.subscribe(async (patient: PatientItem, updatedResource?: any) => {
            if (!patient || this.isLoading) return;

            let patientListItem = this.patients.find(o => o.encounterId == patient.encounterId);
            if (patientListItem) // check if the given resource is the latest assessment to update signalreiter 10
            {
                if (updatedResource?.resourceType === 'Flag') {
                    patientListItem.flags = <any>updatedResource;
                    await PatientItem.ReadAndCalculateMarksAndVisitNumber(patientListItem);

                    patientListItem.careLevelColor = patient.careLevelColor;
                    patientListItem.careLevel = patient.careLevel;
                    patientListItem.careLevelText = patient.careLevelText;
                    patientListItem.SPI = patient.SPI;
                    PatientItem.ReadRelations(patient);

                    // for the signalreiter items that are overriden per config read the status from the flags:
                    const currentConfig = await ConfigService.LoadConfigOverride(patient?.ward, patient);

                    const signalReiterOverrides = currentConfig?.features?.signalreiter;
                    if (signalReiterOverrides) {
                        for (const so of signalReiterOverrides.signals) {
                            const flag = patient.flags?.code?.coding?.find(o => o.system.endsWith(`/mark_${so.signal}`));
                            if (flag) {
                                const m = patient.marks.find(o => o.index == so.signal);
                                if (m) {
                                    m.checked = flag.code === 'true';

                                    if (!m.checked) {
                                        m.red = false;
                                        m.yellow = false;
                                    }
                                }
                            }
                        }
                    }

                    this.checkForNursingSignalColor(patient);
                }
            }
        });

        this.isOffline = FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled ? FhirService.OfflineClientSettings.isOffline : false;
        this.transferText = this.isOffline ? 'Fälle auf den Server hochladen' : 'Fälle auf den lokalen Rechner kopieren';

        App.PatientList = this;
    }

    nursingVisitsEnabled: boolean = false;
    nursingVisitsTargetSignal: number = -1;
    nursingVisitResubmissionColor: string = '#c35add';

    checkForNursingSignalColor(patient: PatientItem) {
        if (patient?.marks && this.nursingVisitsEnabled) {
            const nursingMark = patient.marks.find(o => o.index === this.nursingVisitsTargetSignal);
            if (nursingMark) {
                if (!nursingMark.checked)
                    nursingMark.checked = true;
                const newColor = NursingVisitsProtocol.GetSignalColor(patient, this.nursingVisitResubmissionColor);
                nursingMark.overrideColor = newColor;

                // because the aurelia ui does not always update correctly, we help a bit by completely replacing the ring-element
                const ringClassList = `.patient-list-signal-ring.mark-${nursingMark.index}`;
                const selector = `[data-encounter-id="${patient.encounterId}"] ${ringClassList}`;
                const ring: HTMLDivElement = document.querySelector(selector);
                if (ring) {
                    if (ring.parentNode) {
                        const td = <HTMLTableCellElement>ring.parentNode;
                        td.innerHTML = '';
                        const div = document.createElement('div');
                        div.setAttribute('class', ringClassList.replace(/\./g, ' ').trim());
                        div.setAttribute('style', `background-color: ${newColor}!important; color: white`);

                        div.innerText = String(nursingMark.index);

                        td.appendChild(div);
                    }
                }

                /*if (ConfigService.Debug) {
                    const css = `background: ${newColor}`;
                    console.debug(`%c  `, css, `Patient ${patient.display} changed NursingColor on signal ${nursingMark.index} to ${newColor}`);
                } */
            }
        }
    }

    detached() {
        this.patientChangeNotifier.unsubscribe(this.subScriberId);
    }

    public updateFlags(patient: PatientItem) {
        if (!patient)
            return;

        if (this.patients) {
            const patientTagItem = this.patients.find(o => o.encounterId == patient.encounterId);
            if (!patientTagItem) {
                return;
            }

            PatientItem.ReadMark5(patientTagItem);
            PatientItem.ReadMark1(patientTagItem);
            PatientItem.ReadRelations(patientTagItem);

            this.checkForNursingSignalColor(patient);
        }
    }

    public updateMark5FromPatient(patient: PatientItem) {
        if (this.patients) {
            const patientTagItem = this.patients.find(o => o.encounterId == patient.encounterId);
            if (patientTagItem) {
                patientTagItem.encounter = NitTools.Clone(patient.encounter);
                PatientItem.ReadMark5(patientTagItem);
            }
        }
    }

    async beforeNavigation(encounterId: string) {
        if (RuntimeInfo.DataProxy.enabled === true) {
            if (PatientService.LatestPatient && PatientService.LatestPatient.id && PatientService.LatestPatient.encounterId === encounterId) {
            } else {
                try {
                    await this.encounterService.pump(encounterId);
                } catch {
                    await this.encounterService.pump(encounterId.replace(/-/gi, '_'));
                }
            }
        }
    }

    async navigateToEncounter(encounterId: string) {
        RuntimeInfo.IsLoading = true;
        this.router.navigateToRoute('encounter', {id: encounterId});
        RuntimeInfo.ClosePatientList();
        RuntimeInfo.IsLoading = false;
    }

    async navigateToPatient(patient) {
        return await this.navigateToPatientAsync(patient.encounterId);
    }

    async navigateToPatientAsync(encounterId: string) {
        if (!encounterId) return;
        this.encounterId = encounterId;
        await this.beforeNavigation(encounterId);
        this.navigateToEncounter(encounterId).catch(err => console.warn(err));
    }

    private clearSearch() {
        this.searchName = '';
        this.roomColor = 'transparent';
        this.effectiveSearch = undefined;
        this.searchInput.focus();
    }

    async wardIdChanged(newId) {
        if (!this.loaded) return;
        HomeList.CurrentWardId = newId;
        await this.load(true);
    }

    async refreshWard(silent: boolean = false) {
        if (!silent) this.isLoading = true;

        try {
            await PatientItem.Refresh(this.fhirService, this.locationService, this.wardId);
            this.taskQueue.queueTask(() => PatientItem.HighlightRelatedEncounters(this.patients.find(o => o.encounterId == this.encounterId)));
        } finally {
            this.isLoading = false;
        }
    }

    async showOfflineList() {
        this.dialogService.open({
            viewModel: TakeOfflineList,
            model: {
                items: this.patients,
                list: this
            }
        })
    }

    protected selectedEncounterIdChanged(newId: string) {
        if (!this.patients) return;
        this.patients.filter(o => o.selected).forEach(item => item.selected = false);
        const pat = this.patients.find(o => o.encounterId === newId);
        if (pat) pat.selected = true;
    }

    protected async selfList(): Promise<any[]> {
        return await PatientItem.List(this.fhirService, this.locationService, this.wardId);
    }

    public OnWardLoaded: Function;
    public isFromLoginScreen: boolean = false;

    async load(updatePractitioner: boolean = false) {
        // when this comes from Login-Screen or Auto-Login do not update the Pracitioner-Resource
        if (updatePractitioner && this.isFromLoginScreen)
            updatePractitioner = false;

        let param = {
            hash: sessionStorage.getItem(environment.sessionName),
            server: this.fhirService.endPoint,
            ward: "",
            wardList: `Location?identifier=${SystemHeaders.systemBase}locationWard|&_count=500`,
            isPrincipa: UserService.Login.usePrincipa,
            practitionerRoleId: UserService.Role ? UserService.Role.id : ''
        };

        this.listParam = encodeURIComponent(btoa(JSON.stringify(param)))

        this.isLoading = true;

        try {
            if (!this.wardId) return;
            const selectedWard = LocationService.LocationById(this.wardId);
            this.wardName = selectedWard ? selectedWard.name : this.i18n.tr("unknown");

            if (updatePractitioner) {
                this.userService.updateSelectedWard(this.wardId)
                    .catch(e => {
                        console.warn(e.message || JSON.stringify(e));
                    });
            }

            let wd = this.wards.find(o => o.id === this.wardId);
            if (typeof wd !== "undefined" && wd.id) {
                RuntimeInfo.CurrentWardId = wd.id;
                const cfg = await ConfigService.LoadConfigOverride(wd.name);
                const nursingConfig = cfg?.forms?.find(o => o.route === 'nursingvisit');

                this.nursingVisitsEnabled = nursingConfig?.enabled && typeof nursingConfig.settings?.targetSignal === "number";
                this.nursingVisitsTargetSignal = this.nursingVisitsEnabled ? nursingConfig?.settings?.targetSignal : -1;
                this.nursingVisitResubmissionColor = nursingConfig?.settings?.resubmissionWarningColor || this.nursingVisitResubmissionColor;

                if (window.location.href.indexOf('embedded=1') === -1) {
                    // when NOT running embedded

                    this.roomsCache = undefined;
                    await PatientItem.List(this.fhirService, this.locationService, this.wardId);
                    if (this.patients) {
                        this.patients.forEach(p => this.updateFlags(p));
                    }

                    if (!this.isFromLoginScreen) {
                        if ((window.location.href.indexOf('hideList=1') === -1 && ConfigService.AutomaticallyJumpToFirstEncounter === true)
                            || !this.selectedEncounterId
                        ) {
                            if (window.location.href.indexOf('/ward/') === -1) {
                                this.clearSearch();
                                this.taskQueue.queueTask(() => {
                                    if (this.patients && this.patients[0]) {
                                        this.navigateToPatientAsync(this.patients[0].encounterId);
                                    }
                                })
                            }
                        }
                    }

                    this.taskQueue.queueTask(() => PatientItem.HighlightRelatedEncounters(this.patients.find(o => o.encounterId == this.encounterId)));
                }
            }
        } catch (error) {
            console.warn(error.message || JSON.stringify(error));
        } finally {
            this.isLoading = false;
        }

        if (typeof this.OnWardLoaded === "function") {
            try {
                this.OnWardLoaded(this);
            } catch (ex) {
                console.warn(ex);
            }
        }

        this.isLoading = false;
    }

    async searchForPatient() {
        if (this.searchName === "") {
            this.effectiveSearch = undefined;
            return;
        } else {
            this.effectiveSearch = (this.searchName ?? '').toUpperCase();
        }
    }

    async showRoomColors() {
        function resetGlobalOverlay() {
            $(globalOverlay).hide();
            $(globalOverlay)[0].onclick = undefined;
        }

        if (RuntimeInfo.Embedded || !this.colorButton || !this.patientListRoot) return;
        const left = $(this.colorButton).offset().left;
        const top = $(this.colorButton).offset().top;
        const overlay = document.createElement('div');

        overlay.classList.add('room-color-parent');
        overlay.style.left = `${left}px`;
        overlay.style.top = `${top}px`;
        overlay.style.width = `300px`;
        overlay.style.maxHeight = `${window.innerHeight - top}px`;
        overlay.style.left = `-300px`;

        const h2 = document.createElement('h3');
        h2.classList.add('color-overlay-header');
        h2.innerText = 'Bereich auswählen';
        overlay.appendChild(h2);

        if (!this.roomsCache) {
            const rooms: any[] = [{
                name: 'Alle Bereiche',
                extension: [{url: "http://nursit.institute/additional-info/roomColor", valueString: "transparent"}]
            }];

            rooms.push(...<any[]>await this.fhirService.fetch(`Location?partof=${this.wardId}&_status=active`));
            this.roomsCache = rooms;
        }

        const colors: { colorValue: string, label: string }[] = [];

        // 1st make disctinct colors list from rooms
        for (const room of this.roomsCache.filter(o => o.extension)) {
            const colorExtension = room.extension.find(o => o.url.endsWith('roomColor'));
            if (colorExtension) {
                let color = colorExtension.valueString;
                if (typeof colors.find(o => o.colorValue === color) === "undefined") {
                    colors.push({colorValue: color, label: undefined});
                }
            }
        }

        // 2nd add the labels to the color which have no label assigned. First unEmpty wins
        for (const room of this.roomsCache.filter(o => o.extension)) {
            const labelExtension = room.extension.find(o => o.url.endsWith('roomLabel'));
            if (labelExtension) {
                const lbl = String(labelExtension.valueString).trim();

                const colorExtension = room.extension.find(o => o.url.endsWith('roomColor'));
                let color = colorExtension.valueString;
                const colorItem = colors.find(o => o.colorValue === color);
                if (colorItem && !colorItem.label && lbl) {
                    colorItem.label = lbl;
                }
            }
        }

        for (const color of colors) {
            const lbl = document.createElement('label');
            lbl.classList.add('room-color-item')
            if (color.colorValue !== 'transparent') {
                if (!color.label) color.label = ' ';
                lbl.innerHTML = `<span style="display: block; background-color: ${color.colorValue}; width:100%"></span>
                                 <span style="width: 100%; display:inline-block; text-align: center">${color.label}&nbsp;</span><br />`;
            } else {
                lbl.innerText = 'Alle Bereiche';
                lbl.style.display = 'block';
                lbl.style.paddingLeft = '12px';
                lbl.style.marginBottom = '0';
                lbl.style.width = '100%';
                lbl.style.paddingTop = '10px';
            }

            lbl.onclick = (e) => {
                e.cancelBubble = true;
                e.preventDefault();

                this.roomColor = color.colorValue;
                $(overlay).stop().animate({left: -300}, () => {
                    resetGlobalOverlay();
                });
            }

            overlay.appendChild(lbl);
        }

        const globalOverlay = <HTMLDivElement>document.getElementById('globalOverlay');
        globalOverlay.innerHTML = '';

        globalOverlay.appendChild(overlay);
        globalOverlay.onclick = () => resetGlobalOverlay();

        $(globalOverlay).show();
        $(overlay).stop().animate({left: 0});
    }

    /** sets the combo containing the location-wards to the initial result and then hooks the change event of the combo */
    protected selectInitialWard() {
        if (window.location.href.indexOf('hideList=1') === -1) {
            if (!RuntimeInfo.Embedded && this.userService.practitionerRole && this.userService.practitionerRole.location) {
                let initial = this.userService.practitionerRole.location.find(o => o.identifier && o.identifier.system.endsWith('/practitioner-default-ward'));
                if (initial) {
                    let wId = initial.reference.split('/')[1];
                    let newInitial = this.wards.find(o => o.id === wId);
                    if (newInitial) this.wardId = newInitial.id;
                } else {
                    this.wardId = this.wards && this.wards[0] ? this.wards[0].id : '';
                }
            }
        }
    }
}
